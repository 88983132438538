import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { jSith } from "../../../../util/jquery-replacement";
import { LineItemI } from "../../../../pojo/Pricing";
import { PricingService } from "../../../../services/pricing.service";
import { TypeManagerDecorator } from "../../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-pricing-subtotal-charges")
@Component({
    selector: "s25-ng-pricing-subtotal-charges",
    template: `@if (init) {
        <div>
            @if (sumProfileOccAdjustments !== undefined) {
                <div class="c-margin-bottom--half pricing-tooltip-wrapper" tabindex="0">
                    {{ sumProfileOccAdjustments | currency }}
                    <s25-ng-info-message [isPopover]="true">
                        <h4>{{ tooltipHeader }}</h4>
                        <p>{{ tooltipMessage }}</p>
                    </s25-ng-info-message>
                </div>
            }
            @for (item of adjustments; track item) {
                <div class="c-margin-bottom--half">
                    <span>{{ item.total_charge | currency }}</span>
                </div>
            }
            @if (modelBean.profileTax) {
                <div>{{ modelBean.profileTax }}</div>
            }
        </div>
    }`,
    styles: `
        :host ::ng-deep .c-svgIcon {
            position: relative;
            bottom: 1px;
        }

        .pricing-tooltip-wrapper {
            display: flex;
            position: relative;
        }

        :host ::ng-deep s25-ng-info-message {
            position: unset;
            transition: unset;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25PricingSubtotalChargesComponent implements OnInit {
    @Input() modelBean: any;

    init: boolean;
    adjustments: any = [];
    sumProfileOccAdjustments: number;
    tooltipMessage: string =
        "The total for all occurrence adjustments is added to the subtotal of pre-adjustment occurrences as well as adjustments to items not associated with occurrences to arrive at a total for the organization.";
    tooltipHeader = "Net Total Adjustments";

    constructor(private cd: ChangeDetectorRef) {}

    ngOnInit() {
        if (this.modelBean.occView) {
            const nonOccAdjustments = PricingService.agg.adjustments.subtotal(
                this.modelBean.noOccItems.filter((item: LineItemI) => item.bill_item_type_id !== 2),
            );

            this.sumProfileOccAdjustments =
                this.modelBean.profileSubtotals.reduce(
                    (sum: number, item: LineItemI) => sum + item.profileAdjustments,
                    0,
                ) - nonOccAdjustments;
        }

        jSith.forEach(this.modelBean.allBillItems, (_, billItem) => {
            //passed in by pricing-service footerF supplied to getPricingOrganizationListFn
            if (billItem.bill_item_type_id === -1 && !isNaN(parseFloat(billItem.adjustment_amt))) {
                this.adjustments.push(billItem);
            }
        });

        jSith.forEach(this.modelBean.allBillItems, (_, billItem) => {
            if (billItem.bill_item_type_id === -1 && !isNaN(parseFloat(billItem.adjustment_percent))) {
                this.adjustments.push(billItem);
            }
        });

        this.init = true;
    }

    protected readonly PricingService = PricingService;
}
